// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-frontend-development-index-js": () => import("./../../../src/pages/services/frontend-development/index.js" /* webpackChunkName: "component---src-pages-services-frontend-development-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-jamstack-website-index-js": () => import("./../../../src/pages/services/jamstack-website/index.js" /* webpackChunkName: "component---src-pages-services-jamstack-website-index-js" */),
  "component---src-pages-services-user-interface-development-index-js": () => import("./../../../src/pages/services/user-interface-development/index.js" /* webpackChunkName: "component---src-pages-services-user-interface-development-index-js" */),
  "component---src-pages-start-project-index-js": () => import("./../../../src/pages/start-project/index.js" /* webpackChunkName: "component---src-pages-start-project-index-js" */),
  "component---src-pages-start-project-thank-you-js": () => import("./../../../src/pages/start-project/thank-you.js" /* webpackChunkName: "component---src-pages-start-project-thank-you-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */)
}

